import { Environment } from '@fxp/fxpservices';
import { aurorasit } from '../configs/appSettings.aurorasit';
import { dev } from '../configs/appSettings.dev';
import { prod } from '../configs/appSettings.prod';
import { sit } from '../configs/appSettings.sit';
import { uat } from '../configs/appSettings.uat';
import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';


export const getEnvironment = () => {
  // returns the 3 letter representation of the environment that's used everywhere
  const environment = window["FxpAppSettings"].EnvironmentName;

  const envIndex = Object.values(Environment).indexOf(environment);
  if (envIndex === -1) {
    // environment not found in the list of values, default to dev
    return "DEV";
  } else {
    // return the key, which is the 3 letter representation, and turn it into uppercase
    return Object.keys(Environment)[envIndex].toUpperCase();
  }
};

export const appSettings = (): IPartnerAppSettings => {
  let appSetting;
  switch (window['FxpAppSettings'].EnvironmentName) {
    case Environment.Dev: appSetting = dev; break;
    case Environment.Sit: appSetting = sit; break;
    case Environment.Uat: appSetting = uat; break;
    case Environment.AuroraSit: appSetting = aurorasit; break;
    case Environment.Prod: appSetting = prod; break;
    default: appSetting = dev; break;
  }

  return appSetting;
};

export const applicationName = (): string => {
  let applicationName;
  switch (window['FxpAppSettings'].EnvironmentName) {
    case Environment.Dev: applicationName = "Units Management"; break;
    case Environment.Sit: applicationName = "Unit Management"; break;
    case Environment.Uat: applicationName = "Unit Management"; break;
    case Environment.AuroraSit: applicationName = "Units Management"; break;
    case Environment.Prod: applicationName = "Unit Management"; break;
    default: applicationName = dev; break;
  }

  return applicationName;
};
