import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const prod: IPartnerAppSettings = {
  cdnBaseUrl: 'https://umtui.azureedge.net/um-react',
  serviceEndPoints: [
    {
      serviceEndPoint: "https://projectservices-api-prod.z01.azurefd.net/api/",
      clientId: "83902542-ffa9-4272-9d09-31b93eccabfa"
    },
    {
      clientId: "83902542-ffa9-4272-9d09-31b93eccabfa",
      serviceEndPoint: "https://projectservices-api-prod.z01.azurefd.net/api/"
    }
  ],
  UmAppConfiguration: {
    projectServiceBaseUri:
      "https://projectservices-api-prod.z01.azurefd.net/api/",
    projectServiceFunctionV3BaseUrl: "https://projectservices-api-prod.z01.azurefd.net/api",
    projectServiceSubscriptionKey: "ed3cd6b9cd9b454ca93264c5b3351220",
    isBulkApprovalDisabled: false
  }
};
