import { IAppService, IStateConfig, IFxpAppContext, IServiceEndPoints, IRouteInfo, IPartnerBundle } from '@fxp/fxpservices';
import { appSettings, applicationName } from './environments/common/appsettingsGenerator';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class SampleApplication_routes implements IAppService {

  getAuthExceptionEndpoints(): Array<any> {
    return [];
  }

  getRoutes(fxpContext: IFxpAppContext): IRouteInfo {    

    const unitSubmissionAppRoute: IStateConfig = {
      name: 'UnitSubmission',
      url: '/um/unitsubmission',
generatedBundle: 'c9f8a0ff5742-UnitSubmissionApp-Bundle',
      componentFramework: 'React',
      data: {
        headerName: 'Units Submission',
        breadcrumbText: 'UnitSubmission',
        pageTitle: 'UnitSubmission',
        partnerTelemetryName: 'Unit Management'
      }
    }  

    const unitApprovalAppRoute: IStateConfig = {
      name: 'UnitApproval',
      url: '/um/unitapproval',
generatedBundle: 'c9f8a0ff5742-UnitsApprovalWrapperComponent-Bundle',
      componentFramework: 'React',
      data: {
        headerName: 'Unit Approval',
        breadcrumbText: 'UnitApproval',
        pageTitle: 'Unit Approval',
        partnerTelemetryName: 'Unit Management'
      }
    }  


    const routeInfo: IRouteInfo = {
      applicationName: "UnitsManagement",
      sharedBundles: [],
      routes: [unitSubmissionAppRoute, unitApprovalAppRoute]
    }

    return routeInfo;
  }

  getServiceEndPoints(): Array<IServiceEndPoints> {

    return appSettings().serviceEndPoints;
  }

  getBundles(): IPartnerBundle[] {
    const baseUrl = appSettings().cdnBaseUrl;
    const bundle: IPartnerBundle = {
      name: "UnitsManagement-bundle",
      files: [
        `${baseUrl}/vendor.bundle.js`,
        `${baseUrl}/styles.bundle.js`,
        `${baseUrl}/main.bundle.js`,
      ],
      sequentialLoading: true,
    };

    return [bundle];
  }
  public getGeneratedBundles() {
    var baseUrl = '';
    var currentScriptUrl = document.currentScript['src'];
    if (currentScriptUrl.indexOf('exclude.inline.bundle') > 0) {
      currentScriptUrl = Error().stack.match(/(https?:\/\/[^ ]*)/g);
      baseUrl = currentScriptUrl
        .filter((item) => item.indexOf('localhost:5000') === -1)[0]
        .toLowerCase();
      baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    } else {
      baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/'));
    }
    if (baseUrl.endsWith("/")){
        baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    }
    return [
            {
                name: 'c9f8a0ff5742-UnitSubmissionApp-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/UnitSubmissionApp.vendor.bundle.js',
                    baseUrl + '/UnitSubmissionApp.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'c9f8a0ff5742-UnitsApprovalWrapperComponent-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/UnitsApprovalWrapperComponent.vendor.bundle.js',
                    baseUrl + '/UnitsApprovalWrapperComponent.bundle.js'
                ],
                sequentialLoading: true
            },

    ]
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(SampleApplication_routes, 'UnitsManagement');
